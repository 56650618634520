import ButtonLink from '@components/ButtonLink';
import { Colors, Text, Title } from '@components/global.styles';
import * as React from 'react';
import { Fragment } from 'react';
import { Row } from 'shards-react';
import { Containe, Content } from './styles';

const Listen: React.FC = () => {
  return (
    <Fragment>
      <Containe>
        <Row>
          <Content>
            <Title
              className="op1 big-text"
              style={{ marginTop: '2rem' }}
              colors={Colors.gray}
            >
              Ascultă online!
            </Title>
            <br />
            <Text className="small-text" colors={Colors.gray}>
              Mai jos aveți canalele noastre oficiale de streaming.
            </Text>
            <br /> <br /> <br />
            <br />
            <Title
              className="op1 medium-text"
              style={{ marginTop: '2rem' }}
              colors={Colors.gray}
            >
              Spotify
            </Title>
            <div
              className="content-button01"
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <iframe
                src="https://open.spotify.com/embed/show/3Z4Jzq750d8fMCiQThYJ6m?theme=0"
                width="100%"
                height="232"
                frameBorder="0"
                allow="encrypted-media"
              >
                Loading...
              </iframe>
            </div>
            <br />
            <Title
              className="op1 medium-text"
              style={{ marginTop: '2rem' }}
              colors={Colors.gray}
            >
              Spreaker
            </Title>
            <div
              className="content-button01"
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <iframe
                src="https://widget.spreaker.com/player?show_id=4747940&theme=dark&playlist=show&playlist-continuous=false&chapters-image=true&episode_image_position=right&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true"
                width="100%"
                height="350px"
                allow="encrypted-media"
                frameBorder="0"
              >
                Loading...
              </iframe>
            </div>
            <br /> <br /> <br />
          </Content>
        </Row>
      </Containe>
    </Fragment>
  );
};

export default Listen;
